<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>Авторы</h4>
      <b-button variant="outline-primary" @click="addClicked">Добавить</b-button>
    </div>
    <b-table
      class="mt-3"
      :fields="fields"
      :items="list"
      @row-clicked="(row) => rowClicked(row)"
      tbody-tr-class="pointer"
    >
      <template #cell(last_name)="{ value, item }">
        <div class="d-flex justify-content-between align-items-center flex-nowrap">
          <span class="mr-3">{{ value }}</span>
          <div class="text-nowrap">
            <b-icon
              icon="pencil"
              variant="primary"
              class="mr-3"
              @click="rowClicked(item, $event)"
            />
            <b-icon icon="trash-fill" variant="primary" @click="deleteClicked(item, $event)" />
          </div>
        </div>
      </template>
      <template #cell(description)="{ value }">
        <div class="restricted-height">{{ value }}</div>
      </template>
    </b-table>
    <div class="d-flex justify-content-center">
      <b-pagination class="m-auto" v-model="currentPage" :total-rows="total" per-page="25" />
    </div>
  </div>
</template>

<script>
import { Authors } from '@/request';

export default {
  name: 'Authors',
  data() {
    return {
      list: [],
      fields: [
        { key: 'first_name', label: 'Имя' },
        { key: 'last_name', label: 'Фамилия' },
      ],
      currentPage: 1,
      total: 0,
    };
  },
  mounted() {
    this.loadAuthors(this.currentPage);
  },
  methods: {
    async loadAuthors(page) {
      try {
        const { data, total } = await Authors.getAuthors({ page });
        this.list = data;
        this.total = total;
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    addClicked() {
      this.$router.push({ name: 'NewAuthor' });
    },
    rowClicked(row, event = undefined) {
      event?.stopPropagation();

      this.$router.push({ name: 'EditAuthor', params: { id: row.id } });
    },
    async deleteClicked(row, event) {
      event?.stopPropagation();

      const userAgreed = await this.$bvModal.msgBoxConfirm(
        `Вы действительно хотите удалить автора: ${row.first_name} ${row.last_name}?`,
        {
          okTitle: 'Удалить',
          cancelTitle: 'Отмена',
          cancelVariant: 'outline-secondary',
        },
      );

      if (!userAgreed) {
        return;
      }

      try {
        await Authors.deleteAuthor(row.id);
        this.$bvToast.toast('Автор удален', { variant: 'success' });
        await this.loadAuthors();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
  },
  watch: {
    currentPage(newVal) {
      this.loadPlaces(newVal);
    },
  },
};
</script>

<style lang="scss" scoped></style>
